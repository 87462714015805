/*================================================================================
SECTIONING
================================================================================*/

.page-main {
    padding-top: $base-spacing;

    @include media-query (min, $wide-start) {
        padding-top: $base-and-half-spacing;
    }
}

.wrapper {
}

.container {
   @include container;
}

hr {
    clear: both;
    margin-bottom: $base-spacing;
    border: none;
    border-bottom: 1px solid $silver-lt;
    padding-bottom: $half-spacing;
    height: 1px;
}
