.filter {
  display: none;
  @include media-query(min, $lap-start) {
    display: block;
  }
}

.filter-title {
  @include visuallyhidden;
}

.filter-content {

}

.filter-subtitle {
  @include visuallyhidden;
}


.filter-options {
  @include media-query(min, $lap-start) {
    margin-top: $double-spacing * 1.5;
  }
}

.filter-options-title {
  @include font-size($h4-font-size);
  margin-bottom: $base-spacing;

}

.filter-options-content {

  .items {
    list-style-type: none;
  }

  .item {
    @include media-query(min, $lap-start) {
      margin-bottom: $half-spacing;
      margin-left: $base-spacing;

      a {
        display: block;
        position: relative;
        text-decoration: none;
        color: $base-color;
        @include font-size($small-font-size);

        @include media-query(min, $desk-start) {
          @include font-size($base-font-size)
        }

        &:before {
          content: '';
          position: absolute;
          background-color: $white;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border: 2px solid $charcoal;
          left: -55px;
          top: -13px;
          transform: scale(0.5);
          box-shadow: inset 0 0 0 5px #fff;
        }

        @include hover {

          &:before {
            background-color: $black;
          }
        }
      }
    }

    .count {
      &:before {
        content: '(';
      }

      &:after {
        content: ')';
      }
    }


    .filter-count-label {
      @include visuallyhidden;
    }
  }
}

.filter-current {
  .items {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;

    .action.remove {
      text-decoration: none;
      color: $black;
      @include font-size($small-font-size);
      font-weight: bold;

      @include hover {
        text-decoration: underline;
        color: $accent;
      }
    }
  }

  .item {

  }

  .filter-label {
    display: block;
    font-weight: bold;
  }

  .filter-value {
    display: block;
  }
}

.filter-actions {
  margin-bottom: $base-spacing;

  .filter-clear {
    text-decoration: none;
    color: $black;
    @include font-size($small-font-size);
    font-weight: bold;

    @include hover {
      text-decoration: underline;
      color: $accent;
    }
  }
}