.grouped-products__filter {
  .filter-current {
    margin-bottom: $base-spacing;
    .filter-current-subtitle {
      display: block;
      margin-bottom: $half-spacing;
      @include font-size(20px);
      text-transform: uppercase;
    }
    .items {
    }

    .item {
      position: relative;
      list-style: none;
      border: 1px solid $black;
      padding: 5px 20px;
      &:nth-child(2n) {
        border-top: none;
      }

      .filter-label {
        display: inline-block;

      }

      .filter-value {
        position: relative;
        display: inline-block;
        margin-left: $base-spacing;

        &:after {
          content: ":";
          display: block;
          position: absolute;
          left: -20px;
          top: -1px;
        }
      }
    }
    .action.remove {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;

      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: url(../img/icon-close-red.svg) no-repeat 50%;
        background-size: contain;
      }
      span {
        @include visuallyhidden;
      }
    }
  }
  .filter-content {
    position: relative;
  }
  .filter-clear {
    position: absolute;
    top: -40px;
    right: 0;
    @include font-size(20px);
    text-transform: uppercase;
    @include media-query(min, $lap-start) {
      top: -8px;
    }

  }
}
