.local-dealer {
  margin-bottom: $double-spacing;

  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing * 2;
  }
}

.local-dealer__wrapper {
  @include media-query(min, $lap-start) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.local-dealer__form {

  @include media-query(min, $lap-start) {
    width: calc(50% - #{$double-spacing});
  }

}

.local-dealer__label {

}

.local-dealer__heading {
  @include font-size(30px);

  @include media-query(min, $lap-start) {
    @include font-size($h1-font-size);
  }

}

.local-dealer__sub-heading {

  margin-bottom: $base-spacing;
}

.local-dealer-input__label {


}

.local-dealer-input__title {


}

.local-dealer__field {
  display: flex;

  .control {
    @include media-query(min, $lap-start) {
      flex-grow: 1;
    }
  }


}

.local-dealer__button.button {
white-space: nowrap;
  span {
    @include font-size(13px);

    @include media-query(min, $lap-start) {
      @include font-size(16px);
    }
  }

  svg {
    margin-left: 10px;
  }
}

.local-dealer__link {

  text-decoration: none;
  @include font-size(15px);
  font-weight: bold;
  display: inline-block;
  margin-bottom: $base-spacing;
  cursor: pointer;
  color: $accent;

  @include hover {
    color: darken($accent, 10%);
    text-decoration: underline;
  }
}

.local-dealer__img {
  display: none;

  @include media-query(min, $lap-start) {
    display: block;
    width: calc(50% - #{$double-spacing});

  }

}