.account {
  .block-title {
    display: block;
    width: 100%;
    margin-bottom: $base-spacing;
    padding-bottom: $base-spacing;
    @include font-size($h3-font-size);
    font-weight: 500;
    border-bottom: 1px solid $silver-lt;

    @include media-query(min, $lap-start) {
      @include font-size($h3-font-size);
    }

    a {
      display: inline-block;
      @include font-size($micro-font-size);
      text-decoration: none;
      margin-left: $half-spacing;
    }
  }

  .column.main .block {
    margin-bottom: $double-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .box-title {
    @include font-size($h4-font-size);
    @include media-query(min, $lap-start) {
      margin-bottom: $base-spacing;
      display: block;
    }
  }

  .box {
    margin-bottom: $base-spacing;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-query(min, $lap-start) {
      margin-bottom: 0;
    }
  }

  .box-actions {
    .action {
      text-decoration: none;
      @include hover {
        text-decoration: underline;
      }
    }
  }

  .block-dashboard-info {

    .block-content {
      @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;

        .box {
          width: calc(50% - #{$double-spacing});
        }
      }

      .change-password {
        position: relative;
        padding-left: $half-spacing;

        &:before {
          content: '';
          left: 2px;
          bottom: 0;
          position: absolute;
          width: 1px;
          height: 16px;
          background-color: $black;
        }
      }
    }
  }

  .block-dashboard-addresses {

    .block-content {
      @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;

        .box {
          width: calc(50% - #{$double-spacing});
        }
      }
    }
  }

  .block-addresses-default {
    .block-content {
      @include media-query(min, $lap-start) {
        display: flex;
        justify-content: space-between;

        .box {
          width: calc(50% - #{$double-spacing});
        }

      }
    }
  }
}
