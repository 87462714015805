/*========================================
HEADINGS
========================================*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: $hn-font-weight;
    line-height: $hn-line-height;
    margin-bottom: $base-spacing;

    a {
        text-decoration: none;
    }
}

h1,
.h1 {
    @include font-size($h1-font-size);
}

h2,
.h2 {
    @include font-size($h2-font-size);

}

h3,
.h3 {
    padding-top: $half-spacing;
    @include font-size($h3-font-size);
}

.h3--lg {
    padding-top: $half-spacing;
    @include font-size($h3-font-size);
}
