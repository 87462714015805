/*========================================
Account Pages: Address book address block
========================================*/

.block-addresses-list {

    .block-content {
        display: block;
    }

    .items.addresses {
        list-style: none;
        margin: 0;

        @include media-query(min, $lap-start) {
            display: flex;
            flex-wrap: wrap;
        }

        > .item {
            padding: $base-spacing;
            border-radius: $base-radius;
            border: 1px solid $mercury-dk;
            margin-bottom: $base-spacing;

            @include media-query(max, $lap-start) {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-query(min, $lap-start) {
                width: calc(50% - #{$half-spacing});
                margin-right: $base-spacing;
                margin-bottom: $base-spacing;

                &:nth-child(even) {
                    margin-right: 0;
                }
            }

            .actions {
                .action.edit {
                    margin-right: $half-spacing;
                }
            }
        }
    }
}
