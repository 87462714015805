.checkout-cart-index {
  .cart-container {

    @include media-query(min, $lap-start) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

  }

  .cart-summary {
    background: $silver-lt;
    padding: $base-spacing;

    @include media-query(min, $lap-start) {
      width: calc(30% - #{$base-spacing});
      order: 2;
    }


    button {
      @include font-size(12px);
      width: 100%;

      @include media-query(min, $lap-start) {
        @include font-size(14px);

      }

      @include media-query(min, $desk-start) {
        @include font-size($base-font-size);
      }
    }
  }

  .summary.title {
    display: block;
    @include font-size($h3-font-size);
    margin-bottom: $half-spacing;

    @include media-query(min, $lap-start) {
      margin-bottom: $base-spacing;
      @include font-size(35px);
    }
  }

  .block.shipping {
    position: relative;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size($base-font-size);

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {

      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;

          }
        }
      }
    }

    .content {

    }

    .estimate {

    }

    .legend {
      display: none;
    }

    .note {

    }

    .rate {

    }

    .methods {

    }

    .item-title {

    }

    .item-options {

      .item {
        display: flex;

        .label {
          .price {
            margin-left: 2px;
          }
        }
      }
    }
  }

  .fieldset.rate {
    margin-bottom: $base-spacing;
    border-bottom: 1px solid $silver-lt;

  }

  .cart-totals {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .table-wrapper {

    }

    .table.totals {

    }

    .table-caption {

    }

    tr {
      padding: 0;
      display: table-row;

    }

    th {
      display: table-cell;
      padding: 0;
      vertical-align: middle;
      text-align: left;

    }

    td {
      display: table-cell;
      text-align: right;
    }

    td[data-th]:before {
      display: none;
    }

  }

  .block.discount {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {
      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;

          }
        }
      }

    }

    .coupon {
      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

      }
    }
  }

  .block.giftcard {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {

    }

    .title {

      strong {
        position: relative;
        display: block;
        @include font-size(13px);
        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }


        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {
      .title {
        strong {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;
          }
        }
      }

    }

    .giftcard {
      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

      }
    }

    .actions-toolbar {

      .primary {
        @include media-query(min, $lap-start) {
          margin-bottom: $half-spacing;
        }

        .action {

        }
      }

      .secondary {
        @include media-query(min, $lap-start) {
          margin-bottom: 0;
        }

        .action {
          @include media-query(min, $lap-start) {
            padding: 0;
          }

          span {

          }

        }
      }
    }
  }

  .checkout-methods-items {
    list-style-type: none;
    margin-left: 0;

    @include media-query(min, $lap-start) {

    }

    .item {
      @include media-query(min, $lap-start) {
        margin-bottom: $half-spacing;
      }
    }
  }

  .multicheckout {
    text-decoration: none;
    @include font-size(13px);
    font-weight: bold;
    color: $accent;

    @include media-query(min, $lap-start) {
      @include font-size($base-font-size);
    }

    @include hover {
      text-decoration: underline;
    }
  }

  .action.checkout {

  }

  .form-cart {

    @include media-query(min, $lap-start) {
      width: calc(70% - #{$base-spacing});
      order: 1;
    }

    .cart {

    }

    thead {
      @include media-query(min, $lap-start) {
        border-bottom: 1px solid $silver-lt;
      }
    }

    .table-caption {

    }

    tr {
      .col.item {
        text-align: left;
      }

      th {
        @include media-query(min, $lap-start) {
          text-align: right;
          padding: $base-spacing 0;
          @include font-size($base-font-size);
        }

      }
    }

    .cart.item {
      @include media-query(min, $desk-start) {
        border-bottom: 1px solid $silver-lt;
      }

    }


    .item-info {
      padding: 0;

      td {
        @include media-query(min, $lap-start) {
          text-align: right;
          padding: $half-spacing 0;
          @include font-size($base-font-size);
        }
      }

      .col.item {
        display: flex;
        align-items: center;

        @include media-query(min, $lap-start) {
          align-items: initial;
        }

        .product-item-photo {

        }

        .product-item-details {


          .product-item-name {
            a {
              text-decoration: none;
              @include hover {
                text-decoration: underline;
              }
            }
          }
        }

        &[data-th]:before {
          display: none;
        }

      }

      .col.qty {


        .label {
          @include visuallyhidden;

        }

        .field.qty {
          display: inline-block;
          margin-bottom: 0;
        }

        .control.qty {
          margin-left: auto;

          input {
            width: 50px;
            text-align: right;
            height: 20px;
            padding: 0;
            border: none;
          }
        }
      }
    }

    .item-actions {
      td {

      }

      .actions-toolbar {

        @include media-query(min, $lap-start) {
          text-align: right;
        }

      }
    }

    .gift-options-cart-item {

    }

    .action-edit {
      margin-right: $half-spacing / 2;
      text-decoration: none;
      @include font-size(13px);
      font-weight: bold;
      color: $accent;
      @include hover {
        text-decoration: underline;
      }
    }

    .action-delete {
      text-decoration: none;
      @include font-size(13px);
      font-weight: bold;
      color: $accent;
      @include hover {
        text-decoration: underline;
      }
    }
  }

  .cart.main.actions {
    .continue {
      display: block;
      margin-bottom: $base-spacing;
      @include font-size($base-font-size);
      text-decoration: none;
      font-weight: bold;
      color: $accent;

      @include media-query(min, $lap-start) {
        text-align: right;
      }

      @include hover {
        text-decoration: underline;
      }
    }

    .update {
      margin-bottom: $base-spacing;
      @include media-query(min, $lap-start) {
        margin-bottom: 0;
      }
    }

    .clear {
      margin-bottom: $base-spacing;

      @include media-query(min, $lap-start) {
        margin-bottom: 0;
      }
    }
  }

  #gift-options-cart {
    @include media-query(min, $lap-start) {
      width: 70%;
      order: 3;
    }
  }

  .cart-gift-item {

    @include media-query(min, $lap-start) {

    }
  }

  .gift-item-block {
    position: relative;

    .title {
      font-weight: bold;

      span {
        position: relative;
        display: block;
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 2px;
          width: 30px;
          height: 20px;
          background: url(../img/arrow-down.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }

    &.active {

      .title {
        span {
          &:after {
            background: url(../img/arrow-up.svg) no-repeat center;
            background-size: 30px;
          }
        }
      }
    }

    .content {

    }

    .gift-options {
      .gift-options-content {

      }
    }

    .gift-wrapping {

    }

    .gift-options-title {
      @include font-size(12px)
    }

    .gift-receipt {
      display: flex;

      .label {
        @include font-size(13px);

        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }
      }
    }

    .gift-printed-card {
      display: flex;

      .label {
        @include font-size(13px);
        @include media-query(min, $lap-start) {
          @include font-size($base-font-size);
        }
      }
    }
  }
}


