/*========================================
Paths
========================================*/
$font-path: "../fonts";
$image-path: "../img/";
$svg-path: "../svg/";

/*========================================
Colour
========================================*/
//Shades
$black: #1B161C;
$charcoal: #454545;
$grey: #6a6a6a;
$lightgrey: lightgrey;
$grey-lt: #999999;
$silver: #c4c4c4;
$silver-lt: #e6e6e6;
$mercury: #f2f2f2;
$mercury-dk: darken($mercury, 7%);
$smoke: #fafafa;
$cream-dk: #cbc1b2;

//#C0AF91
$cream: #fefbf6;
$white: #ffffff;

//Brand
$primary: $black;
$accent: #E30613;
$accent-dk: #B50812;

$orange: #ff5d1b;
$green: #0E5C53;
$pink: #ec668d;
$gold: #c5a666;
$yellow: #fec584;

//Special
$link: $primary;
$link-hover: darken($primary, 15%);
$alert: #cccc00;
$failure: #ed8380;

//social
$facebook: #3b5999;
$twitter: #00acee;

// other

/*========================================
Typography
========================================*/

// Font stacks
$sans: 'Helvetica', sans-serif;
$sans-condensed: 'Helvetica Condensed' , sans-serif;

// Base
$base-font-size: 16px;
$base-line-height: 1.625;
$button-line-height: 1.1875;
$line-height-ratio: (round(($base-line-height / $base-font-size) * 100)) / 100;
$base-font-family: $sans;
$base-color: $black;
$uppercase-spacing: 0.05em;

// Headings
$hn-font-weight: bold;
$hn-line-height: 1.03;
$hn-font-family: $sans;
$hn-color: $black;

$h1-font-size: 52px;
$h2-font-size: 42px;
$h3-font-size: 23px;
$h4-font-size: 20px;
$h5-font-size: 18px;

// Special
$large-size: 50px;
$lede-font-size: 20px;
$lede-line-height: 1.6;
$small-font-size: 12px;
$table-font-size: 14px;
$micro-font-size: 10px;

/*========================================
Layout
========================================*/

// Dimensions
$border-box: true;
$base-spacing: 20px;
$double-spacing: ceil($base-spacing * 2);
$half-spacing: ceil($base-spacing / 2);
$micro-spacing: ceil($half-spacing / 2);
$base-and-half-spacing: ceil($base-spacing + $half-spacing);
$base-radius: 4px;
$half-radius: ceil($base-radius / 2);
$input-height: 47px;
$button-height: 48px;

// Grids
$page-width: 1220px;
$palm-start: 420px;
$lap-start: 765px;
$desk-start: 1015px;
$wide-start: 1200px;

$modal-max-width: 540px;

$breakpoints: (
        "palm" 0 $lap-start 4% (3, 4),
        "lap" $lap-start $desk-start 4% (3, 4, 6, 8),
        "desk" $desk-start $wide-start 2% (6, 8, 10, 12),
        "wide" $wide-start 0 2% (6, 8, 10, 12)
);
