.account-nav {

}

.account-nav-title {

}

.account-nav-content {

  .nav.items {
    list-style: none;
    margin-left: 0;
  }

  .nav.item {

    @include media-query(min, $lap-start) {
      margin-bottom: $base-spacing;
    }

    &.current {
      color: $accent;
      @include font-size(18px);
      font-weight: bold;
    }

    .delimiter {
      border-top: 1px solid $silver-lt;
      display: block;
      margin: $half-spacing 0;
    }

    a {
      text-decoration: none;
      color: $black;
      @include font-size(18px);
      font-weight: bold;

      @include hover {
        text-decoration: underline;
        color: $accent;
      }
    }
  }
}