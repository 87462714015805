.application-grid-category-landing {
  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing;
  }
}
.application-grid__title{
  @include font-size(33px);
  text-transform: uppercase;
  text-align: center;
  @include media-query(min, $lap-start) {
    text-align: left;
  }
  @include media-query(min, $desk-start) {
    @include font-size(52px);
  }
}

.application-grid__content {

  p {
    @include font-size(13px);

    @include media-query(min, $desk-start) {
      @include font-size(20px);
    }
  }
}

.application-grid-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-query(min, $lap-start) {
    justify-content: start;
  }
}
.application-grid__item {
  width: calc(50% - #{$base-spacing});
  margin-left: $double-spacing;
  margin-bottom: $half-spacing;

  @include media-query-bracket(0, $lap-start) {
    &:nth-child(odd) {
      margin-left: 0;
    }
  }

  @include media-query( min, $lap-start) {
    box-shadow: 0 0 0 1px $mercury;
    border: 3px solid transparent;
    border-radius: 2px;
    margin-bottom: $base-spacing;
    cursor: pointer;
    transition: all .3s ease-in-out;

    @include hover {
      border: 3px solid $accent;
      box-shadow: 0 0 0 1px $accent;
      margin-top: -$half-spacing;
      padding-top: $half-spacing;

      .application-grid__image {
        top: -10px;
      }
    }

    &:nth-child(1) ,  &:nth-child(2) {
      width: calc(50% - #{$base-spacing});
      margin-left: $double-spacing;

      .application-grid__wrapper {
        display: flex;

      }
      .application-grid__content {
        padding: $base-spacing;
      }

      .application-grid__image-link{
        display: block;
      }
      .application-grid__image-wrapper {
        @include media-query(min, $lap-start) {
          width: 50%;
          margin-bottom: 0;
          padding-bottom: 30%;
        }
      }
      .application-grid__image {

      }
      .application-grid__content {
        @include media-query(min, $lap-start) {
          width: 50%;
        }
      }
    }

    &:nth-child(n + 3) {
      width: calc(16.6663% - (#{$base-spacing * 5} / 6));
      margin-left: $base-spacing;

      .application-grid__content {
        text-align: center;
        p {
          display: none;
        }
      }
    }
    &:nth-child(3) {
      margin-left: 0;
    }
    &:nth-child(8n + 1) {
      margin-left: 0;
    }


  }

  p {

    @include font-size(13px);

    @include media-query(min, $lap-start) {
      @include font-size(16px);
    }
  }
}

.application-grid__wrapper {

}

.application-grid__image-link {

}
.application-grid__image-wrapper {
  position: relative;
  padding-bottom: 50%;
  margin-bottom: $half-spacing;
}
.application-grid__image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;


}

.application-grid__content {
  text-align: center;
  @include media-query(min, $lap-start) {
    text-align: left;
  }
}

.application-grid__link {
  text-decoration: none;
  @include font-size(15px);
  line-height: 1.2em;
  font-weight: bold;
  display: block;
  margin-bottom: $half-spacing /2;
  color: $accent;

  @include hover {
    color: $accent;
    text-decoration: none;
  }
}