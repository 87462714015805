.site-footer {
  background-color: $black;
  color: $white;

}

.site-footer__container {

}

.site-footer__newsletter {
  padding-bottom: $double-spacing;
  padding-top: $double-spacing;
  border-bottom: 1px solid $silver-lt;
  border-top: 1px solid $silver-lt;
  background-color: white;
  color: $black;
}

.footer-newsletter {

}

.footer-newsletter__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;


  @include media-query (min, $lap-start) {
    flex-wrap: nowrap;
  }

  ::placeholder {
    display: none;
    @include font-size($base-font-size);
    color: $white;

    @include media-query(min, $lap-start) {
      color: $base-color;
    }
  }

  .subscribe__heading {
    display: none;
    @include font-size($h3-font-size);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;

    @include media-query(min, $lap-start) {
      display: block;
    }
  }

  .subscribe__sub-heading {
    @include font-size($h4-font-size);
    font-weight: bold;

    @include media-query(min, $lap-start) {
      @include font-size(16px);
      font-weight: normal;
    }
  }
}

.footer-newsletter__label {
  text-align: center;
  margin-bottom: $half-spacing;

  @include media-query(min, $lap-start) {
    margin-right: $double-spacing * 2;
    text-align: left;
  }
}

.footer-newsletter__field {
  flex: auto;
  display: flex;
  margin-bottom: 0;
  align-items: flex-start;
  max-width: 100%;

  .label {
    margin-bottom: 0;
  }

  .control {
    flex-grow: 1;
  }

  .actions {

    .button {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      display: flex;
    }

    span {
      margin-right: $half-spacing;
      @include media-query(min, $lap-start) {
        @include visuallyhidden;
      }
    }
  }
}

.footer-newsletter-input__label {
  display: block;
  width: 100%;
  margin-bottom: 0;

  @include media-query(min, $lap-start) {
    display: none;
  }
}

.footer-newsletter-input__title {
  @include font-size(15px);

  @include media-query(min, $lap-start) {
    display: none;
  }
}

.site-footer__info {
  @include media-query(min, $lap-start) {
    padding-top: 60px;
  }

}

.site-footer__info--container {
  padding-top: $base-and-half-spacing;
  width: 100%;

  @include media-query(min, $lap-start) {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: $double-spacing;
  }

}

.site-footer__content {
  margin-bottom: $base-spacing;

  &:first-of-type {
    display: none;

    @include media-query(min, $lap-start) {
      display: block;
    }
  }

}

.site-footer__title {
  @include font-size(15px);
  position: relative;
  margin-bottom: $half-spacing;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    background-image: url("../img/expand_more.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-query(min, $lap-start) {
      display: none;
    }
  }

  @include media-query(min, $lap-start) {
    @include font-size(25px);
    text-transform: uppercase;
  }

  &.active:after {
    content: '';
    position: absolute;
    background-image: url("../img/expand_less.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-query(min, $lap-start) {
      display: none;
    }
  }

}


.site-footer__wrapper {
  display: none;

  @include media-query(min, $lap-start) {
    display: block;
  }

  &.active {
    display: block;
  }
}

.site-footer__links {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include font-size(13px);

  @include media-query(min, $lap-start) {
    @include font-size(15px);
  }

  .site-footer__item {
    margin: 0;
    padding: 0;

  }

  .site-footer__link {
    text-decoration: none;
    color: $white;
    line-height: 26px;
    @include hover {
      color: $accent;
      border-bottom: 1px solid $accent;
    }
  }

}

.site-footer__social-contact {
  margin-top: $base-and-half-spacing;
  padding-top: $base-and-half-spacing;
  border-top: 1px solid $silver-lt;

  @include media-query(min, $desk-start) {
    border-top: none;
  }
}

.social-links__title {
  text-align: center;
  margin-bottom: $base-spacing;

  &:after {
    display: none;
  }
}

.social-links {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  max-width: 220px;
  margin: 0 auto;
  margin-bottom: $base-and-half-spacing;

  @include media-query(min, $desk-start) {
    margin: 0;
    margin-right: auto;
  }

  .social-links__item {
    width: 40px;
    height: 40px;

  }

  .svg-icon {
    width: 40px;
    height: 40px;
    color: $white;

    @include hover {
      color: $accent;
    }
  }

  .social-links__link {

  }
}

.site-footer__logo {
  margin: 0 auto;

  @include media-query(min, $lap-start) {
    display: none;
  }
}

.footer.links {
  list-style-type: none;
  @include font-size(13px);
  display: block;
  text-align: center;
  margin-left: 0;
  margin-bottom: 0;

  @include media-query(min, $desk-start) {
    display: flex;
    @include container;
    align-items: baseline;
  }

  .nav.item {
    margin-right: $half-spacing;
    padding-right: $half-spacing;
    position: relative;
    display: inline-block;


    &:after {
      content: '';
      position: absolute;
      height: 13px;
      width: 1px;
      background-color: $white;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-of-type:after {
      display: none;
    }

    a {
      text-decoration: none;
      color: $white;

      @include hover {
        color: $accent;
        border-bottom: 1px solid $accent;
      }
    }
  }
}

.copyright {
  margin-top: $half-spacing;
  padding-bottom: $half-spacing;
  display: block;

  span {
    display: block;
    text-align: center;
  }

  @include media-query(min, $desk-start) {
    display: inline-block;
    margin-left: auto;
  }
}