.page-layout-checkout {

  .page-header {
    @include container;
    @include media-query(min, $lap-start) {
      margin-bottom: $double-spacing;
    }
  }

  .header {


  }

  .site-header__logo {
    display: block;
    margin: 0 auto;
  }

  .page-title-wrapper {
    display: none;
  }
}
