.account {

  .page-title-wrapper {
    .page-title {
      @include font-size(30px);

      @include media-query(min, $lap-start) {
        @include font-size($h2-font-size);
        display: inline-block;
        margin-right: $base-spacing;
      }
    }
  }

  #my-orders-table > tbody tr {

  }

  th, td {
    padding: $half-spacing 0;
  }

  br {
    display: none;
  }

  .order-status {
    text-transform: uppercase;
    margin-bottom: $base-spacing;
    display: inline-block;
    @include font-size($h4-font-size);
  }

  .order-date {
    display: flex;

    .label {
      margin-right: 5px;
    }
  }

  .actions-toolbar {
    padding-top: $base-spacing;
    @include media-query(min, $lap-start) {
      margin-bottom: $double-spacing;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }

  .order-products-toolbar {
    text-align: left;

    .pager {
      display: flex;
      justify-content: space-between;
    }

    .toolbar-amount {
      width: 50%;
      @include font-size(14px);
    }

    .limiter {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      max-width: 100px;
      @include font-size(14px);

      .limiter-label {
        margin-right: 5px;
        font-weight: 100;
        order: 1;
      }

      .limiter-text {
        order: 2;
      }

      .limiter-options {
        order: 3;

      }
    }
  }

  .block-order-details-view {
    .block-title {
      @include font-size(30px);

      @include media-query(min, $lap-start) {
        @include font-size($h2-font-size);
        border-bottom: none;
      }
    }

    .block-content {
      @include media-query(min, $lap-start) {
        display: flex;

        .box {
          width: calc(25% - #{$base-spacing * 3} / 4);
          margin-left: $double-spacing *2;

          &:nth-child(4n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .field.choice {
    display: flex;
  }
}

.sales-order-view {
  #my-orders-table > tbody {

    @include hover {

    }
  }
}

