.related-products__heading {
  @include container;
  margin-bottom: $base-spacing;
  text-transform: uppercase;
}
.related-products {
  display: block;
  @include container;

  .related-products__items {

    display: block;
  }

  .related-products__item {
    box-shadow: 0 0 0 -1px $silver-lt;
    border: 1px solid $silver-lt;
    @include media-query-bracket(0, $lap-start) {
      width: calc(50% - (#{$base-spacing} / 2));
      margin-left: $base-spacing !important;

    }

    @include media-query-bracket($lap-start, $desk-start) {
      width: calc(25% - (#{$base-spacing * 3} / 4));
      margin-left: $base-spacing !important;
    }

    @include media-query(min, $desk-start) {
      width: calc(16.6666% - (#{$base-spacing * 5} / 6));
      margin-left: $base-spacing !important;
    }

    @include hover {
      margin-top: 0;
      padding-top: 0;
      border: 1px solid $accent;
    }
    .photo.image {
      transform: translate(0, 0);
    }
  }

  .flickity-button {
    background: $accent;
  }
  .flickity-button-icon {
    color: $white;
  }
  .flickity-prev-next-button.previous {
    left: -70px;
  }

  .flickity-prev-next-button.next {
    right: -70px;
  }

  .flickity-page-dots {
    @include media-query(min, $lap-start) {
      display: none;
    }
  }

  .product-item-info {
    &:after {
      right: 0;
      bottom: 0;
    }

  }
}
