.site-nav {
    background-color: $black;
    position: relative;
    width: 100%;

    @include media-query (max, $lap-start) {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        height: calc(100vh - 58px);
        z-index: 1;
        transition: transform ease .3s;
        overflow: hidden;

        &.active {
            transform: translateX(0);
        }
    }

    @include media-query (min, $lap-start) {

    }
}

.site-nav__list {
    list-style: none;
    margin: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;


    @include media-query (min, $lap-start) {
        @include container;
        display: flex;
        justify-content: space-between;
    }

    > li {
        border-bottom: 1px solid $charcoal;

        &.parent {
            > a {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include media-query (min, $lap-start) {
                    justify-content: flex-start;
                }

                &:after {
                    content: '';
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    border-left: 8px solid $accent;
                    margin-left: 8px;

                    @include media-query (min, $lap-start) {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        @include media-query (min, $lap-start) {
            border: none;
        }

        > a {
            display: block;
            padding: $half-spacing;
            color: $white;
            text-decoration: none;

            @include hover {
                text-decoration: none;
            }

            @include media-query (min, $lap-start) {
                text-transform: uppercase;
                padding: $half-spacing 0;
                @include font-size($small-font-size);
            }

            @include media-query (min, $desk-start) {
                @include font-size($base-font-size);
            }
        }
    }

    .level0.submenu {
        position: absolute;
        left: 0;
        list-style: none;
        margin: 0;
        background-color: $black;
        z-index: 1;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-query (max, $lap-start) {
            top: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100%;
            transition: transform ease .3s;

            &.active {
                transform: translateX(0);
            }
        }

        @include media-query (min, $lap-start) {
            width: 100%;
            max-width: $page-width;
            margin: 0 auto;
            top: 100%;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: all ease .3s;
            border-top: 1px solid $grey;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }

        > li {
            border-bottom: 1px solid $grey;

            &.parent {
                > a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;
                        border-left: 8px solid $accent;
                        margin-left: 8px;
                    }
                }
            }

            @include media-query (min, $lap-start) {
                border: none;
                width: 25%;
            }

            > a {
                display: block;
                padding: $half-spacing;
                color: $white;
                text-decoration: none;
                width: 100%;

                @include hover {
                    text-decoration: none;
                }

                @include media-query (min, $lap-start) {
                    padding: $half-spacing $base-spacing;
                    @include font-size($small-font-size);

                    @include hover {
                        text-decoration: underline;
                    }
                }

                @include media-query (min, $desk-start) {
                    @include font-size($base-font-size);
                }
            }
        }
    }

    .level1.submenu {
        position: absolute;
        left: 0;
        list-style: none;
        margin: 0;
        background-color: $black;
        z-index: 1;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-query (max, $lap-start) {
            top: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100%;
            transition: transform ease .3s;

            &.active {
                transform: translateX(0);
            }
        }

        @include media-query (min, $lap-start) {
            width: 25%;
            top: 0;
            left: 25%;
            height: 100%;
            border-left: 1px solid $grey;
            opacity: 0;
            visibility: hidden;
            transition: all ease .3s;
            z-index: 2;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }

        > li {
            border-bottom: 1px solid $grey;

            @include media-query (min, $lap-start) {
                border: none;
            }

            > a {
                display: block;
                padding: $half-spacing;
                color: $white;
                text-decoration: none;

                @include hover {
                    text-decoration: none;
                }

                @include media-query (min, $lap-start) {
                    @include font-size($small-font-size);
                    padding: $half-spacing $base-spacing;

                    @include hover {
                        text-decoration: underline;
                    }
                }

                @include media-query (min, $desk-start) {
                    @include font-size($base-font-size);
                    padding: $half-spacing $base-spacing;
                }

            }
        }
    }
}

.submenu-cms {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-height: 100%;
    clear: none;
    color: $white;
    padding: $base-spacing;
    visibility: hidden;
    transition: all ease .3s;
    z-index: 2;
    border-left: 1px solid $grey;
    @include font-size($small-font-size);

    &.active {
        opacity: 1;
        visibility: visible;
    }

    @include media-query (min, $lap-start) {
        display: block;
    }

    @include media-query (min, $desk-start) {
        @include font-size($base-font-size);
    }

    img {
        margin-bottom: $base-spacing;
    }
}

.site-nav__back {
    button {
        background: $accent;
        border: none;
        color: $white;
        padding: $half-spacing;
        width: 100%;
        text-align: left;

        @include hover {
            background: $accent;
            color: $white;
        }
    }

    @include media-query (min, $lap-start) {
        display: none;
    }
}
