/*========================================
DEFAULT MAGENTO TOOLTIP
========================================*/

.field-tooltip {
    &._active {
        .field-tooltip-content {
            display: block;
        }
        .field-tooltip-action {
            background-color: $accent;
        }
    }
}

.field-tooltip-action {
    position: absolute;
    right: $half-spacing;
    top: $half-spacing;
    width: ($base-spacing + 5px);
    height: ($base-spacing + 5px);
    border-radius: 100%;
    background-color: $grey-lt;
    text-align: center;
    font-weight: 400;
    @include font-size(20px);
    line-height: ($base-spacing + 5px);
    color: $white;

    &:before {
        content: "i";
    }

    @include hover {
        outline: none;
        cursor: pointer;
    }
}

.field-tooltip-content {
    display: none;
    position: absolute;
    right: 0;
    padding: $half-spacing;
    background-color: $grey-lt;
    color: $white;
}
