.product-tabs-section__mobile {
  @include media-query(min, $lap-start) {
    display: none;
  }

}
.product-tabs-section {
  clear: both;
 display: none;
  position: relative;

  @include media-query(min, $lap-start) {
    display: block;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $silver-lt;
      top: 70px;
    }
  }
}

.product-tabs {

  .title {
    position: relative;
    padding: $half-spacing 0;
    font-weight: bold;
    @include media-query(min, $lap-start) {
      margin-bottom: $base-spacing;
    }

    &:after {
      content: '';
      position: absolute;
      background-image: url("../img/expand_more_blk.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px;
      height: 24px;
      width: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include media-query(min, $lap-start) {
        display: none;
      }
    }

    &.active {
      @include media-query(min, $lap-start) {
        background-color: $white;
        border-bottom: 1px solid $white;
      }
    }

    &.active:after {
      content: '';
      position: absolute;
      background-image: url("../img/expand_less_blk.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px;
      height: 24px;
      width: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include media-query(min, $lap-start) {
        display: none;
      }
    }

    @include media-query(min, $lap-start) {
      display: inline-block;
      @include font-size(18px);
      text-transform: uppercase;
      border: 1px solid $silver-lt;
      padding: $base-spacing;
      width: 185px;
      text-align: center;
      margin-right: $base-spacing;
      background-color: $silver-lt;
    }
  }

  .switch {
    text-decoration: none;
    display: block;
  }

  .content {
    padding-bottom: $half-spacing;
    @include media-query(min, $lap-start) {
      display: block;
      width: 100%;
    }
  }
}

.product-tabs__wrapper {
  @include media-query(min, $lap-start) {
    margin-bottom: $base-spacing;
  }
}