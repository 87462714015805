/*================================================================================
MEDIA
================================================================================*/

img,
video,
embed {
    display: block;
    border: none;
    max-width: 100%;
    height: auto;
}

/*
 * Figures
 */
figure {
    img {
    }
}
figcaption {
}
