/*================================================================================
MAIN
================================================================================*/

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    min-height: 100%;
    font: #{($base-font-size/16px)*100%}/#{$base-line-height} $base-font-family;
    font-weight: 100;
}

body {
    position: relative;
    background: white;
    color: $base-color;
    text-rendering: optimizeLegibility;

    /* Conditional CSS http://adactio.com/journal/5429/ */
    &:after {
        content: "palm";
        display: none;

        @include media-query(lap) {
            content: "lap";
        }
        @include media-query(desk) {
            content: "desk";
        }
        @include media-query(wide) {
            content: "wide";
        }
    }
}
