.checkout-index-index {
  .columns,
  .container {
    max-width: $desk-start;
  }

  .top-bar__stores {
    display: none;
  }

  .field {
    max-width: 100%;
  }

  .authentication-wrapper {
    margin-bottom: $double-spacing;
    text-align: right;

    @include media-query(min, $lap-start) {
      width: 50%;
      float: right;
      text-align: right;
      margin-bottom: $base-spacing;
    }

    .authentication-dropdown {
      text-align: left;
    }
  }
}

.checkout-container {
  position: relative;
  @include media-query(min, $lap-start) {
    @include clearfix;
  }
}

.opc-progress-bar {
  @include media-query(min, $lap-start) {
    float: left;
    width: 50%;
  }
}

.opc-estimated-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $half-spacing $base-spacing;
  margin-bottom: $double-spacing;
  border: 1px solid $mercury-dk;
  background: $mercury;
  border-radius: $base-radius;

  @include media-query(min, $lap-start) {
    display: none;
  }

  .estimated-label {
    font-weight: 500;
    margin-right: $micro-spacing;
  }

  .action.showcart {
    background-image: url("#{$image-path}/cart-white.svg");
    background-repeat: no-repeat;
    background-position: left $half-spacing center;
    padding-left: ($double-spacing + $half-spacing);
  }
}

.opc-wrapper {
  @include media-query(min, $lap-start) {
    float: left;
    width: 62%;
    padding-right: $double-spacing;
  }
  @include media-query(min, $desk-start) {
    padding-right: ($base-and-half-spacing * 2);
  }
}

.opc-sidebar {
  .modal-inner-wrap {
    @include media-query(min, $lap-start) {
      float: right;
      width: 38%;
      background-color: $silver;
      padding: $base-spacing;
      margin-bottom: $double-spacing;
    }

    .modal-header {
      @include media-query(min, $lap-start) {
        display: none;
      }
    }
  }

  .opc-sidebar__image {
    margin-top: $base-spacing
  }

  .opc-block-summary {
    .title {
      display: block;
      margin-bottom: $base-spacing;
      @include font-size(35px);
      font-weight: bold;
    }
  }

  .items-in-cart {
    position: relative;

    .title {
      position: relative;
      display: block;
      margin-bottom: $base-spacing;
      @include font-size(18px);
      font-weight: bold;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 2px;
        width: 30px;
        height: 20px;
        background: url(../img/arrow-down.svg) no-repeat center;
        background-size: 30px;
      }
    }

    &.active {

      .title {
        &:after {
          background: url(../img/arrow-up.svg) no-repeat center;
          background-size: 30px;
        }
      }
    }
  }

  .minicart-items {
    list-style-type: none;
    margin-left: 0;

    .product-item {
      width: 100%;
      border: none;
      border-top: 1px solid $silver-lt;
      padding: $base-spacing 0;
      margin-left: 0;

      @include hover {
        transform: none;
      }

      .product {

      }

      .product-image-container {
        float: left;
      }

      .product-item-details {
        padding-left: 88px;
      }

      .product-item-inner {
        display: table;
        margin: 0 0 10px;
        width: 100%;
      }

      .product-item-name-block {
        display: table-cell;
        padding-right: 5px;
        text-align: left;
      }

      .product-item-name {
        display: block;
        hyphens: auto;
        margin: 5px 0;
        word-wrap: break-word;
        @include font-size(15px);
        margin-bottom: $half-spacing;
      }

      .details-qty {
        display: table-cell;
        text-align: left;
        .label {
          display: table-cell;
          text-align: left;
          padding-right: $half-spacing;
          @include font-size(15px);

          &:after {
            content: ':';
          }
        }

        .value {
          display: table-cell;
          text-align: left;
          @include font-size(15px);
        }
      }

      .subtotal {
        display: table-cell;
        text-align: right;

        .price-including-tax {
          @include font-size(15px);
        }
        .price {
          @include font-size(15px);
        }
      }
    }
  }
}

.opc {
  list-style: none;
  margin: 0;
}

.step-title {
  display: block;
  margin-bottom: $base-spacing;
  @include font-size(35px);
  font-weight: bold;
  text-align: left;
}

.step-content {
  .methods-shipping {
    .actions-toolbar {
      text-align: right;
    }
  }
}

.table-checkout-shipping-method {
  margin-bottom: $base-spacing;

  thead {
    display: none;
  }

  tbody {
   text-align: left;

    .col-method {

    }

  }

  tr {
    display: table-row;
    padding: $base-spacing 0;
  }

  td {
    display: table-cell;
    width: auto;


    &:first-child {
      width: 20px;
      padding: 0;
      vertical-align: middle;
    }
  }

  input {
    margin: 0;
  }
}



