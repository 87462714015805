/*========================================
TABLES
========================================*/

table, .table {
    width: 100%;
    overflow-x: scroll;

    @include media-query(max, $lap-start) {
        thead {
            display: none;
        }

        tr {
            display: block;
            padding: $half-spacing 0;
            @include clearfix;
        }

        td {
            display: block;
            width: 100%;
            text-align: right;
            padding: ($half-spacing / 2) $half-spacing;

            &[data-th]:before {
                content: attr(data-th) ":";
                margin-right: $half-spacing/2;
                margin-top: 3px;
                font-weight: 500;
                white-space: nowrap;
                float: left;
                @include font-size($micro-font-size);
                text-align: left;
            }
        }

        tfoot {
            tr {
                padding: 0;
            }

            th {
                display: none;
            }
        }
    }
}

caption {
   display: none;
}

th,
td {
    padding: $half-spacing;
    border-bottom: 1px solid $silver-lt;
    text-align: left;
    vertical-align: top;

    @include media-query (min, $desk-start) {
        border-bottom: none;
    }

}

th {
    @include font-size($small-font-size);
    font-family: $sans-condensed;
    font-weight: bold;

}
td {
    font-family: $sans-condensed;
    @include font-size($table-font-size);
    font-weight: normal;

    .items-qty {
        list-style-type: none;
        margin-left: 0;
    }


    &.actions {
        a {
            display: inline;
            font-weight: 400;
            color: $base-color;
            text-decoration: none;

            &:nth-child(2) {
                border-left: 1px solid $grey;
                padding-left: $half-spacing;
                margin-left: $half-spacing;
            }

            @include hover {
                text-decoration: underline;
            }
        }
    }
}

thead {
    th {

    }
}

tfoot td {
}

tfoot th {
    text-align: right;
}

[colspan] {
}

[colspan="1"] {
    text-align: left;
}

[rowspan] {
    vertical-align: middle;
}

[rowspan="1"] {
    vertical-align: top;
}

.numerical {
    text-align: right;
}

/**
 * Column widths
 */
$i: 5;
@while $i < 100 {
    .w#{$i} {
        width: $i * 1%;
    }
    $i: $i + 5;
}

/**
 * Plain
 */
.table--plain {
    &,
    th,
    td {
        border: none;
        background: none;
    }
}

/**
 * Boxed
 */
.table--boxed {
    border: 1px solid $silver-lt;
    border-bottom: 0;
    border-collapse: separate;

    tfoot {
        background: $silver-lt;
    }
}

/**
 * Bordered
 */
.table--bordered {
    border: 1px solid $silver-lt;

    th,
    td {
        border: 1px solid $black;

        @include media-query(min, $lap-start) {
            padding-left: $base-spacing !important;
        }


    }
}

/**
 * Striped
 */

.table--striped {
    margin-bottom: 0;
    tbody tr:nth-of-type(odd) {

    }

    tfoot {
    }
}

/**
 * Hover Rows
 */
.table--hover {
    tbody {
        tr:hover {
            td,
            th {
                background-color: $silver-lt;
            }
        }
    }
}

.table--responsive {
    @include media-query(max, $desk-start) {
        thead {
            display: none;
        }

        tr {
            display: block;
            padding: $half-spacing 0;
            @include clearfix;
        }

        td {
            display: block;
            width: 100%;
            text-align: left;
            padding: ($half-spacing / 2) $base-spacing !important;

            @include media-query(min, $lap-start) {
                width: 50%;
                float: left;
            }

            &[data-th]:before {
                content: attr(data-th)":";
                margin-right: $half-spacing/2;
                margin-top: 3px;
                font-weight: 600;
                white-space: nowrap;
                float: left;
                @include uppercase(2px);
                @include font-size($small-font-size);
                text-align: left;
            }
        }

        tfoot {
            tr {
                padding: 0;
            }
            th {
                display: none;
            }
        }
    }
    @include media-query-bracket(0, $lap-start) {
        td {
            text-align: left;

            &[data-th]:before {
                text-align: left;
            }
        }
    }

}