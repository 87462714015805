.page-with-filter {
  .page-main {
    position: relative;
  }
}

.grouped-products {
  @include media-query(min, $lap-start) {
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $silver-lt;
      top: 130px;
      left: 0;
    }
  }
}

.grouped-products__filter {
  .filter-actions {
    position: relative;
    margin-top: $double-spacing * 1.5;
    @include media-query(min, $lap-start) {
      margin-top: 0;
    }
  }

  .button {

    @include media-query(min, $lap-start) {
      width: 200px;
      top: -70px;
    }
    position: absolute;
    right: 0;
    top: -70px;
    height: 48px;
    padding: 0 20px;
    width: 100%;

    &--close {
      display: none;

      .svg-icon {
        height: 16px;
        width: 16px;
      }
    }

    &--close.active {
      display: block;
    }

    &--filter {
      display: block;
    }

    &--filter.active {
      display: none;
    }

    span {
      float: left;
    }

    .svg-icon {
      float: right;
      margin-left: $half-spacing;
    }
  }


  .filter__wrapper {

    &.active {
      .filter {
        display: block;
      }
    }

    @include media-query (min, $lap-start) {
      position: absolute;
      top: 130px;
      left: 0;
      width: 100%;
      padding: $base-and-half-spacing;
      background-color: $silver-lt;
      border-top: 4px solid $silver;
      border-bottom: 4px solid $silver;
      max-height: 360px;
      overflow: auto;
      margin: 0;
      display: none;

      &.active {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $grey;
      -webkit-box-shadow: 0 0 1px $silver;
    }
  }

  .filter {
    @include container;
  }
}

.filter-options__items {

  list-style-type: none;
  margin: 0;
  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }
}

.filter-options__item {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    width: calc(33.33% - (20px * 2 / 3));
    margin-left: 20px;
    margin-bottom: $half-spacing;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  @include media-query(min, $desk-start) {
    width: calc(33.33% - (150px * 2 / 3));
    margin-left: 150px;
    margin-bottom: $half-spacing;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}

.grouped-products__wrapper {
  .filter {

  }

  .filter-title {

  }

  .filter-content {

  }

  .filter-subtitle {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    text-transform: uppercase;
    overflow: auto;
    @include font-size(20px);
    font-weight: bold;
    margin-bottom: $base-spacing;
  }

  .filter-options {

  }

  .filter-options-content {
    select {
      border: 1px solid $silver;
      background: url(../img/select-down-arrow.svg) no-repeat right 10px center #fff;
      background-size: 15px;
    }

    .items {

    }

    .item {

    }
  }

  .filter-options-title {
    @include font-size($base-font-size);
    margin-bottom: $half-spacing;
  }
}

.grouped-products__list {

  .table__image-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $base-spacing;
    @include media-query(min, $lap-start) {
      flex-wrap: nowrap;
    }
  }

  .table__product-info {
    order: 2;
    @include media-query(min, $lap-start) {
      order: initial;
    }
  }

  .table--filtered {
    margin-top: $double-spacing * 1.5;
    @include media-query(min, $lap-start) {
      flex-wrap: nowrap;
      margin-top: 0;
      margin-bottom: $base-spacing;
      th {
        text-align: center;
      }
      td {
        text-align: center;
        width: 120px;
      }
    }
  }
}

.table-content__wrapper {
  .table:nth-child(odd) {
    tbody {
      background-color: $silver-lt;
    }
  }

  .table:first-of-type {
    @include media-query(min, $lap-start) {
      thead {
        display: table-header-group;
      }
    }
  }

  thead {
    @include media-query(min, $lap-start) {
      display: none;
    }
  }
}

.table__image {
  display: none;
  max-width: 100%;
  flex-shrink: 0;
  height: auto;
  margin-bottom: $base-spacing;

  @include media-query(min, $lap-start) {
    display: block;
    margin-bottom: 0;
  }

  &:nth-child(1n+4) {
    display: block;
  }
}