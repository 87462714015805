/*========================================
SVG ICONS
========================================*/

.svg-icon {
    display: inline-block;
    fill: currentColor !important;
    vertical-align: middle;
    line-height: 1em;
    height: 1em;
    width: 1em;
}
