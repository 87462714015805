.site-links {
    list-style: none;
    margin: 0;
    position: absolute;
    top: $half-spacing;
    right: $base-spacing;

    @include media-query (min, $desk-start) {
        top: $base-spacing;
    }
}

.site-links__item {
    margin-left: $base-spacing;
}

.site-links__content {
    text-decoration: none;
    @include font-size(12px);

    @include media-query (min, $desk-start) {
        @include font-size(14px);
    }
}

.site-links__item--dealer {
    .site-links__content {
        color: $accent;
        text-decoration: underline;
    }
}
