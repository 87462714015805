.page.messages {
  .messages {
    max-width: $page-width;
    margin: 0 auto $base-spacing;
    padding: 0 $half-spacing;

    @include media-query(min, $desk-start) {
      padding: 0 $base-and-half-spacing;
      margin-bottom: $double-spacing;
    }
  }
}

.messages {

}

.message {
  text-align: center;
  border-radius: $base-radius;
  padding: $half-spacing $base-spacing;
  background-color: $primary;
  color: $white;
  font-weight: 500;
  margin-bottom: $base-spacing;

  &.global {
    margin: 0;
  }

  .field & {
    margin-top: $half-spacing;
    margin-bottom: 0;
    text-align: left;
    @include font-size($micro-font-size);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message-success {
  background-color: $green;
}
.message-error {
  background-color: $failure;
}

.message-notice {
  background-color: $alert;
}