.dealer-search {

  margin-bottom: $base-spacing;

  @include media-query(min, $lap-start) {
    border-bottom: 1px solid $silver-lt;
    padding-bottom: $double-spacing;
    margin-bottom: $double-spacing;
  }
}

.dealer-search-header__wrapper {
  text-align: center;
  margin-bottom: $double-spacing;

  @include media-query(min, $lap-start) {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
}

.dealer-search-header__title {
  @include font-size($h2-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($h1-font-size);
    width: 30%;
  }
}

.dealer-search-header__subtitle {
  @include font-size($small-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
    width: 70%;
  }
}

.dealer_search__location {
  display: flex;
}

.dealer_search__name {
  display: flex;
}


// Form Search Location
.searchlocation__container {

  @include media-query(min, $wide-start) {
    display: grid;
    grid-template-columns: 1fr $base-spacing 1fr;
    -ms-grid-columns: 1fr $half-spacing $base-spacing $half-spacing 1fr;
    grid-column-gap: $base-spacing;
  }
}
.cms-index-index {
  .searchlocation__container {
    display: block;
  }
}

form.searchlocation {
  display: flex;
  align-items: flex-end;

  @include media-query(min, $lap-start) {
    margin-bottom: $base-spacing;
  }

  .field.searchlocation {
    flex: auto;
    margin-bottom: 0;

    @include media-query (min, $lap-start) {
      min-width: auto;
      flex: initial;
    }

    @include media-query (min, $wide-start) {
      flex-grow: 1;
      max-width: 100%;
      -ms-grid-column: 1;
      -ms-grid-row-span: 1;
      -ms-grid-row: 1;
    }

    &.searchlocation--block {
      @include media-query (min, $lap-start) {
        min-width: 24.7em;
      }

      @include media-query (min, $desk-start) {
        flex: initial;
      }
    }
  }

  .searchlocation--homepage {
    @include media-query (min, $wide-start) {
      flex-grow: 1;
      max-width: 100%;
    }
  }


  .searchlocation__heading {
    @include font-size($small-font-size);

    @include media-query(min, $lap-start) {
      @include font-size($base-font-size);
    }
  }

  .actions {

    .button {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      max-height: 48px;
      @include font-size($small-font-size);

      @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
        white-space: nowrap;
      }
    }

    span {
      @include visuallyhidden;
    }
  }
}
.searchlocation__error {
  color: $failure;
  font-weight: bold;
  text-align: center;
  margin-bottom: $base-spacing;
}
.searchlocation__geolocation {
  margin-bottom: $half-spacing;
  max-width: 35em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .button--no-style {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      @include font-size($small-font-size);

      @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
      }
    }

    @include hover {
      .searchlocation__btn:before {
        background-color: $white;
        border: $accent 10px solid;
      }
    }
  }

  .searchlocation__btn {
    position: relative;
    margin-left: 20px;

    &:before {
      content: '';
      position: absolute;
      background-color: $accent;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      border-color: #333333;
      left: -28px;
      top: -8px;
      transform: scale(0.5);
    }
  }

  @include media-query(min, $wide-start) {
    max-width: none;
    -ms-grid-column: 1;
    -ms-grid-row-span: 1;
    -ms-grid-row: 2;

  }
}

.searchlocation__geolocation--homepage {
  @include media-query(min, $wide-start) {
    grid-row-start: 2;
    grid-column-start: 1;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
}

.searchlocation__additional {
  display: block;
  font-weight: bold;
  margin-bottom: $half-spacing;

  @include font-size($small-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }

  @include media-query(min, $wide-start) {
    grid-row-start: 1;
    grid-column-start: 2;
    -ms-grid-column: 3;
    -ms-grid-row-span: 1;
    -ms-grid-row: 1;
    margin-bottom: $base-and-half-spacing;
    align-self: flex-end;
    justify-self: center;
  }
}

// Form Search Name

form.searchname {
  display: flex;
  align-items: flex-end;

  @include media-query(min, $wide-start) {
    margin-bottom: $base-spacing;
    grid-column-start: 3;
    grid-row-start: 1;
    -ms-grid-column: 4;
    -ms-grid-row-span: 1;
    -ms-grid-row: 1;
  }

  .field.searchname {
    flex: auto;
    margin-bottom: 0;

    @include media-query (min, $lap-start) {
      min-width: 27.7em;
      flex: initial;
    }

    @include media-query (min, $wide-start) {
      flex-grow: 1;
      max-width: 100%;
    }

    &.searchname--block {
      @include media-query (min, $desk-start) {
        flex: initial;
      }
    }

    .label {
      @include font-size($small-font-size);

      @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
      }
    }
  }

  .actions {

    .button {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      @include font-size($small-font-size);

      @include media-query(min, $lap-start) {
        @include font-size($base-font-size);
      }
    }

    span {
      @include visuallyhidden;
    }
  }
}

.dealer-map-filter__wrapper {
  @include media-query(min, $desk-start) {
    display: flex;
    justify-content: space-between;
  }
}

.dealer-map__filter {
  margin-bottom: $double-spacing;

  @include media-query(min, $desk-start) {
    width: calc(40% - #{$double-spacing});
  }
}

.dealer-map {
  display: none;
  @include media-query(min, $lap-start) {
    display: block;
    width: 100%;
    height: 400px;
    margin-bottom: $double-spacing;
  }
  @include media-query(min, $desk-start) {
    width: calc(60% - #{$double-spacing});
  }
}

.dealer-map__header {
  text-align: center;
}

.dealer-map__filer-title {
  background-color: $silver-lt;
  padding: $half-spacing 0;
  padding-left: $half-spacing;
  margin-left: -$half-spacing;
  margin-right: -$half-spacing;
  margin-bottom: $base-spacing;
}
.dealer-map__info {
  @include font-size($h4-font-size);
  @include media-query(min, $lap-start) {
    @include font-size(30px);
    text-align: center;
    margin-bottom: $double-spacing;
  }
}
.dealer-map__filer-wrapper {
  margin-bottom: $base-spacing;

  &.active {
    .dealer-map__filer-header:after {
      background-image: url(../img/expand_less_blk.svg);
    }
  }
}

.dealer-map__filer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: $base-spacing;
  @include font-size($base-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($h4-font-size);
    margin-bottom: $base-spacing;
  }

  &:after {
    content: "";
    display: block;
    background-image: url(../img/expand_more_blk.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;
  }
}

.stockist-type-filter {
  margin-bottom: $base-spacing;
  list-style-type: none;
  margin-left: 0;
  &--top{
    margin-left: $base-and-half-spacing;
    margin-top: -25px;
  }
}


.stockist-type-filter__item {
  margin-bottom: $half-spacing;

  label {
    display: flex;
  }

  span {
    @include font-size($small-font-size);

    @include media-query(min, $lap-start) {
      @include font-size($base-font-size);
    }
  }
}

.button-dealer-finder {
  display: block;
  margin: 0 auto;
  margin-bottom: $double-spacing;

  @include media-query(min, $lap-start) {
    display: none;
  }
}

.dealer-search__results-wrapper {

}

.dealer-search__dealers-list {
  list-style-type: none;
  margin-left: 0;


  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }
}
.dealer-search__dealers-link {
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}
.dealer-search__dealers-item {
  margin-bottom: $base-spacing;
  border: 1px solid $silver-lt;
  padding: $half-spacing;

  @include hover {
    border: 1px solid $accent;
  }

  @include media-query(min, $lap-start) {
    width: calc(33.3333% - (#{$base-spacing * 2} / 3));
    margin-left: $base-spacing;
    padding: $base-spacing;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
}

.dealer-search__dealer-title {
  position: relative;
  margin-bottom: $half-spacing;
  color: $accent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include font-size($table-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }

  &:after {
    content: "";
    display: block;
    background-image: url(../img/arrow_right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;


    @include media-query(min, $lap-start) {
      display: none;
    }
  }
}

.dealer-search__dealer-address {
  @include font-size($small-font-size);

  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }
}

.dealer-search__dealer-miles {
  @include font-size($small-font-size);
  font-weight: bold;
  margin-bottom: 0;
  @include media-query(min, $lap-start) {
    @include font-size($base-font-size);
  }
}