.breadcrumbs {
    display: none;
    @include container;
    margin-top: $base-spacing;

    @include media-query (min, $lap-start) {
        display: block;
    }

    .items {
        display: flex;
        list-style: none;
        margin: 0;
    }

    .item {

        &:after {
            content: '/';
            color: $silver;
            padding: 0 4px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }


        a {
            color: $accent;
            font-weight: bold;
            text-decoration: none;
            @include font-size(14px);

            @include hover {
                text-decoration: none;
            }
        }

        strong {
            font-weight: 400;
            @include font-size(14px);
        }
    }
}
