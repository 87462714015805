.page-products {
    .toolbar {
        display: none;
    }
}

.products-list ~ .toolbar,
.products-grid ~ .toolbar {
    display: block;

    .modes {
        display: none;
    }

    .pages {
        .pages-label {
            display: none;
        }

        .pages-items {
            list-style-type: none;
            margin-left: 0;
            display: flex;
            justify-content: center;
        }
        .item {
            @include font-size(30px);
            cursor: pointer;
            &.current {
                border: 2px solid $mercury;
                .page {
                    span {
                        color: $black;
                    }
                }

                @include hover {
                    border: 2px solid $accent;
                }
            }

            &.pages-item-next {
                @include visuallyhidden;
            }
            &.pages-item-previous {
                @include visuallyhidden;
            }
        }

        .page {
            display: block;
            padding: 0 15px;
            .label {
                @include visuallyhidden;
            }

            span {
                display: block;
                color: $accent;
            }
            text-decoration: none;
            @include hover {
                color: $accent;
            }
        }
    }

    .toolbar-amount {
        display: none;
    }

    .limiter {
     display: none;
    }

    .toolbar-sorter {
        display: none;
    }
}