.info-block {
  text-align: center;
  margin-bottom: $double-spacing;

  @include media-query(min, $lap-start) {
    margin-bottom: ($double-spacing * 2);
  }
}

.info-block__title {
  @include font-size(30px);
  @include media-query(min, $lap-start) {
    @include font-size($h3-font-size);
  }
}

.info-block__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-query(min, $lap-start) {
    justify-content: start;
  }
}

.info-block__content {
  position: relative;
  width: calc(50% - #{$base-spacing});
  margin-left: $double-spacing;

  @include media-query-bracket(0, $lap-start) {


    &:nth-child(odd) {
      margin-left: 0;
    }


  }

  @include media-query(min, $lap-start) {
    width: calc(33.3333% - (#{$base-spacing * 2} / 3));
    margin-left: $base-spacing;
    transition: all .3s ease-in-out;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    @include hover {
      transform: scaleY(1.03);
      transform-origin: bottom;

      .content__image {
        border: 2px solid $accent;
      }
    }
  }
}

.content__image-link {
  display: block;
  position: relative;
}

.content__link {
  text-decoration: none;
  cursor: pointer;
  @include hover {
    color: $accent;
    text-decoration: none;
  }

  @include media-query(min, $lap-start) {
    position: absolute;
    top: 0;
    left: $base-spacing;
  }

}

.content__title {
  @include font-size(15px);
  font-weight: bold;
  display: block;
  text-align: center;
  margin-bottom: $base-spacing;

  @include media-query(min, $lap-start) {
    @include font-size($h1-font-size);
    color: $white;
  }
}

.content__sub-title {

  @include media-query(min, $lap-start) {
    @include font-size($h1-font-size);
    color: $white;
  }
}

.content__image {

  @include media-query(min, $lap-start) {
    border-radius: 5px;
  }

}

.content__btn-link {
  display: none;

  @include media-query(min, $lap-start) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }
}
