.home-slider {
 margin-bottom: $double-spacing;
}

.home-slider__container {

}

.home-slider__gallery {
  position: relative;
}

.home-slider__placeholder {
  padding-top: 58%;
  box-sizing: content-box;
  background-color: $silver-lt;

  @include media-query(min, $lap-start) {
    padding-top: 28.2%;
  }
}

.home-slider__gallery-items {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity ease .3s;

  &.flickity-enabled {
    opacity: 1;
    display: block;
    width: 100%;
    background-color: $white;
  }

}

.home-slider__item {
  width: 100%;
  @include media-query(min, $lap-start) {
    width: 58.2%;
    height: 100%;
    opacity: 0.4;
    transition: opacity ease .3s;
    background-color: $white;
    padding-left: $half-spacing;
    padding-right: $half-spacing;

    &.is-selected {
      border: 3px solid $accent-dk;
      border-radius: 2px;
      box-shadow: 0 10px 12px 0 rgba(27, 22, 28, 0.1);
      opacity: 1;
      cursor: zoom-in;
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.home-slider__button {
  position: absolute;
  bottom: $double-spacing;
  left: $double-spacing;
  margin-bottom: 0;

}

.home-slider__img {
  width: 100%;
  height: 100%;
}