.warranty-register-index {
  .ui-datepicker {
    padding: $base-spacing;
    border: 1px solid $silver-lt;
  }

  .ui-datepicker-calendar {
    border: none;
  }
}

.warranty-form {
  .field {
    width: 100%;
    max-width: 100%;
  }
}

.warranty-field-sku {

}

.warranty__table {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: $double-spacing;
  }
}

.warranty__summary {

}

.warranty__summary-title {

}

.warranty__summary-details {
  @include font-size(14px);
  font-weight: bold;
}

.warranty__summary-list {
  @include font-size(14px);
}

@media print {
  .warranty-view-index {
    .site-actions, .block-search, nav, footer {
      display: none;
    }

    .site-header {
      background-color: $black !important;
    }

    .site-header__links {
      display: flex;

      a {
        color: $white !important;
        text-decoration: none !important;
      }
    }

    .site-links__item--dealer, .site-links__item--login {
      display: none;
    }

    .site-header__logo {
      display: inline-block;
      background-color: $black !important;
    }
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }

  td {
    padding-left: $base-spacing !important;
  }
  td:nth-of-type(odd) {
    background: $silver-lt !important;
  }

}
.warranty__list-wrapper {
  display:flex;
  justify-content: space-between;
  margin-bottom: $base-spacing;
  flex-wrap: wrap;
}
.warranty__list-item {
  width: 50%;
}