.site-header {
    position: relative;
    border-bottom: 1px solid $black;

    @include media-query (min, $lap-start) {
        border-bottom: 0;
    }
}

.site-header__container {
    display: flex;

    @include media-query (max, $lap-start) {
        padding: 0;
    }

    @include media-query (min, $lap-start) {
        align-items: flex-end;
        margin-bottom: $base-spacing;
        position: relative;
    }
}

.site-header__logo {
    display: flex;
    align-items: center;
    width: 70px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $half-spacing;
    background-color: $black;

    @include media-query (min, 450px) {
        width: 120px;
    }

    @include media-query (min, $lap-start) {
        width: 150px;
        padding: $base-spacing;
        padding-top: ($base-spacing * 2);
    }

    @include media-query (min, $desk-start) {
        width: 236px;
        height: 118px;
        padding: $base-spacing ($base-spacing + 10px);
    }
}

.site-header__search {
    @include media-query (max, $lap-start) {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        padding: $base-spacing;
        transition: transform ease .3s;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        background-color: $white;

        &.active {
            transform: translateX(0);
        }
    }
    @include media-query (min, $lap-start) {
        flex-grow: 1;
        flex-shrink: 1;
    }
}

.site-header__actions {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    @include media-query (min, $lap-start) {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.site-header__links {
    display: none;

    @include media-query (min, $lap-start) {
        display: flex;
    }
}
