/*================================================================================
LINKS
================================================================================*/

a {
    color: $link;
    cursor: pointer;

    @include hover() {
        outline: none;
        color: $link-hover;
        text-decoration: underline;
    }
}


.hidden {
    display: none;
}