/*========================================
PREFORMATTED
========================================*/

pre {
    overflow: auto;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-left: $base-spacing;
}

pre code,
pre samp {
    line-height: 1.25;
}
