.order-links {
    display: flex;
    margin: $base-spacing 0;
    list-style: none;
    border-bottom: 1px solid $mercury-dk;

    .item {
        position: relative;
        top: 1px;

        strong, a {
            display: block;
            padding: $half-spacing;
            border: 1px solid $mercury-dk;
            text-decoration: none;
            background-color: $white;
        }

        strong {
            background-color: $mercury;
        }
    }
}
.orders-history {

    tbody {
        tr {
            @include media-query(min, $desk-start) {
                border-bottom: 1px solid $mercury-dk;
            }

        }
    }

}
.order-details-items {

}

.order-items {

    tbody {

        tr {
            border-bottom: 1px solid $silver-lt;

            &:last-of-type {
                border-bottom: none;
            }
        }

    }

    tfoot {
        background-color: $grey-lt;
        .mark {
            @include media-query(min, $lap-start) {
                padding-right: 65px;
            }
        }
    }

}


.order-title {
    margin: $base-spacing 0;
}
