.category-landing-tile {

  margin-bottom: $base-spacing;

  @include media-query(min, $lap-start) {

    margin-bottom: $double-spacing;
  }
}

.category-landing-tile--desktop {
  position: relative;
}

.category-landing-tile--img {
  display: block;
  position: relative;
  padding-top: 15%;
  @include media-query(min, $lap-start) {
    padding-top: 25%;
  }
  @include media-query(min, $desk-start) {
    padding-top: 35%;
  }

  @include media-query(min, $wide-start) {
    padding-top: 15%;
  }


  picture {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  img {

    width: 100%;
    max-height: 500px;
  }


}


.category-landing-tile__content {
  position: relative;
  background-color: $white;
  padding: $base-spacing;


  @include media-query(min, $lap-start) {
    height: 200px;
    padding: $double-spacing;
  }

  @include media-query(min, $desk-start) {
    height: 300px;
    padding: ($double-spacing * 2);
  }

  p {

    @include font-size(13px);
    text-align: center;

    @include media-query(min, $desk-start) {
      @include font-size(20px);
    }

  }

}

.category-landing-tile__title {
  @include font-size(33px);
  text-transform: uppercase;
  text-align: center;

  @include media-query(min, $desk-start) {
    @include font-size(52px);
  }

}

.category-landing-tiles__item {


  p {

  }

}

.category-landing-tiles__link {


}

.category-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-query(min, $lap-start) {
    justify-content: start;
  }

}

.category-tiles__item {
  width: calc(50% - #{$base-spacing});
  margin-left: $double-spacing;
  margin-bottom: $half-spacing;


  @include media-query-bracket(0, $lap-start) {
    &:nth-child(odd) {
      margin-left: 0;
    }
  }


  @include media-query-bracket($lap-start, $desk-start) {
    width: calc(33.3333% - (#{$base-spacing * 2} / 3));
    margin-left: $base-spacing;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @include media-query(min, $lap-start) {
    margin-bottom: $base-spacing;
  }

  @include media-query(min, $desk-start) {
    width: calc(25% - (#{$base-spacing * 3} / 4));
    margin-left: $base-spacing;


    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }


  p {

    @include font-size(13px);
    text-align: center;

    @include media-query(min, $lap-start) {
      @include font-size(16px);
    }
  }
}

.category-tiles__wrapper {
  transform: translateY(0);
  transition: all .3s ease-in-out;

  @include media-query(min, $lap-start) {
    position: relative;

    @include hover {
      .category-tiles__image-link {
        transform: translateY(-20px);
      }

      .category-tiles__link {
        background-color: $accent;
        color: $white;
        height: 100px;
        transform-origin: bottom;
        padding: 42px 0;
      }

    }
  }
}

.category-tiles__image-link {
  display: block;
  @include media-query(min, $lap-start) {
    transition: all .2s ease-in-out;
  }

}

.category-tiles__image {
  margin-bottom: $half-spacing;
  width: 100%;
  border-radius: 2px 2px 0 0;

}

.category-tiles__link {
  text-decoration: none;
  @include font-size(15px);
  line-height: 1.2em;
  font-weight: bold;
  display: block;
  text-align: center;
  margin-bottom: $half-spacing /2;
  cursor: pointer;

  @include hover {
    color: $accent;
    text-decoration: none;
  }

  @include media-query(min, $lap-start) {
    @include font-size(21px);
    position: absolute;
    width: 100%;
    display: block;
    height: 90px;
    line-height: 1.2em;
    margin-bottom: 0;
    padding: 32px 0;
    bottom: 0;
    color: $white;
    background-color: rgba($black, 0.5);
    border-radius: 0 0 2px 2px;
    transition: all .2s ease-in-out;
  }
}



