.show-page__imag-wrap {
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }
}


.show-page__title-wrap {

}

.show-page__title {
  @include font-size(33px);
  text-align: center;

  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(45px);
  }
}

.show-page__address {
  @include font-size(13px);
  text-align: center;
  font-weight: bold;
  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(15px);
  }
}


.show-page__date {
  @include font-size(13px);
  text-align: center;
  font-weight: bold;
  font-style: italic;
  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(15px);
    margin-bottom: $base-spacing;
  }
}

.show-page__map {
  height: 300px;
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    text-align: left;
    height: 400px;
    margin-bottom: $double-spacing;
  }
}

.show-page__intro {
  @include font-size(13px);
  text-align: center;
  font-weight: normal;
  font-style: italic;

  @include media-query(min, $lap-start) {
    text-align: left;
    margin-bottom: $double-spacing;
  }

}
.show-page__content-wrapper {
  p {
    @include font-size(13px);
    text-align: center;
    @include media-query(min, $lap-start) {
      text-align: left;
      @include font-size(15px);
    }
  }
  @include media-query(min, $lap-start) {
    display: flex;
    flex-wrap: wrap;
  }
}

.show-page__img {
  display: block;
  margin: 0 auto;
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    margin-bottom: 0;
  }
}
.show-page__item-block {
  @include media-query(min, $lap-start) {
    width: calc(25% - (#{$base-spacing * 3} / 4));
    margin-left: $base-spacing;

    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
  @include media-query(min, $desk-start) {
    width: calc(25% - (#{$double-spacing * 3} / 4));
    margin-left: $double-spacing;
  }
}
.show-page__content {

}

.show-page__opening {
  @include font-size(13px);
  text-align: center;
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(15px);
  }

  h4 {
    @include font-size(15px);
    text-align: center;
    font-weight: bold;
    @include media-query(min, $lap-start) {
      text-align: left;
    }
    @include media-query(min, $desk-start) {
      @include font-size(18px);
    }
  }
}

.show-page__contact {
  @include font-size(13px);
  text-align: center;
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(15px);
  }

  h4 {
    @include font-size(15px);
    text-align: center;
    font-weight: bold;
    @include media-query(min, $lap-start) {
      text-align: left;
    }
    @include media-query(min, $desk-start) {
      @include font-size(18px);

    }
  }
}

.show-page__link {
  @include font-size(13px);
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: block;
  margin-bottom: $base-spacing;
  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(15px);
  }
}

