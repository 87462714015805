.company-account-create,
.customer-account-create,
.customer-account-createpassword,
.customer-account-forgotpassword {
    .page-main {
        margin: 0 auto;
        max-width: 34em;
    }
}

.customer-account-login {
    .login-container {
        @include media-query (min, $lap-start) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: $double-spacing;
            grid-row-gap: $double-spacing;
        }

        .block-title {
            margin-bottom: $half-spacing;
            @include font-size($h3-font-size);
        }
    }

    .block-customer-login {
        margin-bottom: $base-spacing;

        @include media-query (min, $lap-start) {
            margin-bottom: 0;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column: 1 / span 1;
            grid-row: 1 / span 2;
        }
    }

    .block-new-customer {
        padding-top: $base-spacing;
        border-top: 1px solid $mercury-dk;

        @include media-query (min, $lap-start) {
            padding-top: 0;
            border-top: 0;
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
        }
    }

    .block-new-company {
        padding-top: $base-spacing;
        border-top: 1px solid $mercury-dk;

        @include media-query (min, $lap-start) {
            padding-top: 0;
            border-top: 0;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }
    }
}
