.price-box {

}

.price-container {

}

.price-wrapper  {

}

.price {
  color: $accent;
  @include font-size($small-font-size);

  @include media-query(min, $wide-start) {
    @include font-size($base-font-size)
  }
}