.block-search {
    .block-content {
        margin: 0 auto;
        @include media-query (min, $lap-start) {
            padding: 0 ($base-spacing * 2);
        }
        @include media-query (min, $desk-start) {
            padding: 0 ($base-spacing * 3);
            max-width: 770px;
        }
    }

    .block-title,
    label {
        display: none;
    }

    .field {
        margin: 0;
        max-width: none;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $input-height;
        width: $input-height;
        padding: 0;

        .svg-icon {
            width: 1.5em;
            height: 1.5em;
        }
    }
}
