.page-title-wrapper {
  @include container;
  margin-bottom: 30px;

  @include media-query(min, $lap-start) {
    margin-bottom: 60px;
  }

  .page-title {
    text-transform: uppercase;
    @include font-size(30px);

    @include media-query(min, $lap-start) {
      @include font-size($h1-font-size);
    }
  }

  .account & {
    padding: 0;
  }
}
