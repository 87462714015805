.catalog-product-view {

}

.product.media {

  .fotorama__stage {
    border: 1px solid $silver-lt;
    margin-bottom: 25px;
  }

  .fotorama__caption {
    @include visuallyhidden;
  }

  .fotorama__nav {
    margin-bottom: $base-spacing;
  }

}
