/*========================================
Forms
========================================*/
form {
    margin-bottom: $half-spacing;

    &:last-of-type {
        margin-bottom: 0;
    }
}

label,
.label,
dt {
    display: block;
    font-weight: 500;
    @include font-size($base-font-size);
    margin-bottom: $micro-spacing;
    cursor: pointer;
}

select,
textarea,
input {
    appearance: none;
    display: block;
    padding: ($half-spacing + 2) $half-spacing;
    width: 100%;
    line-height: 1;
    background-color: $white;
    border: 1px solid $primary;
    font-weight: 100;
    color: $base-color;
    height: $button-height;
    border-radius: $half-radius;

    &:focus {
        outline: none;
        border-color: $primary;
    }

    &.mage-error {
        border-color: $failure;
        margin-top: 0;
    }

    &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &::placeholder {
        color: $base-color;
    }
}

.mage-error[generated],
.field-error,
.mage-error {
    margin-top: $half-spacing;
    color: $failure;
    @include font-size(16px);
}

input[type="checkbox"] {
    display: block;
    position: relative;
    height: ($button-height / 2);
    width: ($button-height / 2);
    margin-right: $half-spacing;
    margin-bottom: 0;
    cursor: pointer;
    flex-shrink: 0;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom:0;
        left: 0;
        background-size: 15px;
        opacity: 0;
    }

    @include hover {
        border-color: $primary;
    }
}

input[type="checkbox"]:checked {
    &:after {
        opacity: 1;
        background: url("#{$image-path}check-tick.svg") no-repeat center, $accent;
        background-size: 80%;
    }
}

input[type="radio"] {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    height: $base-spacing;
    width: $base-spacing;
    margin-right: $half-spacing;
    margin-bottom: 0;
    cursor: pointer;
    border-radius: 100%;
    flex-shrink: 0;

    &:after {
        content: "";
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background-color: $accent;
        opacity: 0;
        border-radius: 100%;
    }

    @include hover {
        border-color: $accent;
    }
}

input[type="radio"]:checked {
    &:after {
        opacity: 1;
    }
}

select {
    background: url("#{$image-path}arrow-down.svg") no-repeat right $half-spacing center $white;
    background-size: 30px;
    padding-right: $double-spacing + $half-spacing;
    line-height: 1.2;
}

select::-ms-expand {
    display: none;
}

fieldset {
    border: none;
    margin-bottom: $base-spacing;

    &:last-of-type {
        margin-bottom: 0;
    }
}

legend {
    display: block;
    width: 100%;
    margin-bottom: $base-spacing;
    @include font-size($h3-font-size);
    font-weight: 500;
}

.field {
    max-width: 35em;
    margin-bottom: $base-spacing;

    &._error,
    &.field--error {
        select,
        input {
            border-color: $failure;
        }
    }
}

.choice,
.field--type-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: $half-spacing;

    input {
    }

    label,
    .label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        @include font-size($base-font-size);
        font-weight: 100;
        text-transform: none;
        letter-spacing: 0;

        img {
            max-width: $double-spacing;
            margin-right: $half-spacing;
        }

        span, a {
            margin-left: $half-spacing;
        }
    }

    + .choice {
        margin-bottom: $base-spacing;
    }
}

.input-text {
    &::placeholder {
        color: $silver;
    }
}

.input-text--centered {
    text-align: center;
}

.control {
    position: relative;

    .nested {
        padding-top: $half-spacing;
    }
}

.is-email-available {
    position: relative;
    &:after {
        @include overlay-spinner;
        content: " ";
    }
}

.is-email-available--loading {
    > * {
        opacity: 0.3;
    }
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.is-email-available__password {
}

.is-email-available__password--hidden {
    display: none;
}

.control--combine {
    display: flex;
    flex-wrap: wrap;

    input {
        border-radius: $half-radius 0 0 $half-radius;
        border-right: none;
        order: 1;
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
    }

    button,
    .button {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius:  0 $half-radius $half-radius 0;
        order: 2;
        padding: $half-spacing
    }

    div.mage-error {
        order: 3;
        width: 100%;
    }
}

.field.required,
.field._required {
    > label {
        &:after {
            content: " *";
        }
    }
}

//password
.password {
    .control {
        display: flex;
        flex-direction: column;

        input {
            order: 1;
        }

        #password-strength-meter-container {
            order: 2;
        }

        .mage-error[generated] {
            order: 3;
        }
    }
}

.password-strength-meter {
    background-color: $mercury-dk;
    line-height: 32px;
    height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

.password-none {
}

.password-weak .password-strength-meter:before {
    background-color: #f893af;
    width: 25%;
}

.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}

.password-strong .password-strength-meter:before {
    background-color: lighten($green, 15%);
    width: 75%;
}

.password-very-strong .password-strength-meter:before {
    background-color: $green;
    width: 100%;
}

//DOB
.customer-dob {
    button {
        position: absolute;
        top: 0;
        right: 0;
        width: $button-height;
        height: $button-height;
        border: none;
        //background: url("../img/calendar.svg") no-repeat center;
        background-size: $base-and-half-spacing;

        span {
            display: none;
        }
    }

    input {
        padding-right: $button-height;
    }
}

.ui-datepicker {
    background-color: $white;
    padding: $half-spacing;
}

.ui-datepicker-header {
    background-color: $white;
}

.ui-datepicker-prev {
    margin-right: $half-spacing;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    display: inline-block;
    margin-bottom: $half-spacing;
}

.ui-datepicker-title {
    display: flex;
}

.ui-datepicker-month {
    width: calc(50% - #{$half-spacing});
    margin-right: $half-spacing;
    margin-bottom: $half-spacing;
}

.ui-datepicker-year {
    width: calc(50% - #{$half-spacing});
    margin-bottom: $half-spacing;
}

.ui-datepicker-calendar {
    margin-bottom: 0;
    border-left: 1px solid $silver-lt;
    border-right: 1px solid $silver-lt;
}

//Login Form
.form-login {
    .label:after {
        display: none;
    }
    .button {

        width: 100%;
        @include media-query (min, $lap-start){
            margin-bottom: 0;
            width: auto;
        }
    }

    .actions-toolbar {
        @include media-query (min, $lap-start){
            display: flex;
            max-width: 35em;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    .remind {
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;

        @include media-query (min, $lap-start){
            width: auto;
            text-align: left;
        }

        @include hover {
            text-decoration: underline;
        }
    }
}


