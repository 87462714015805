/*================================================================================
Mixins
================================================================================*/

/*========================================
Utilities
========================================*/

/**
 * Output hover rules
 *
   `@include hover();`
 *
 */
@mixin hover {
  &:hover,
  &:focus {
    @content;
  }
}

/**
 * Output uppercase with some letter spacing
 *
   `@include uppercase(0.1em);`
 *
 */
@mixin uppercase($spacing: $uppercase-spacing) {
  text-transform: uppercase;
  letter-spacing: $spacing;
}

/**
 * Output clearfix rules
 *
   `@include clearfix();`
 *
 */
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  zoom: 1;
}

@mixin no-clearfix {
  &:before,
  &:after {
    display: none;
  }
  &:after {
    clear: none;
  }
  zoom: normal;
}

/**
 * Output media query block
 *
   `@include media-query(min, 640px);`
 *
 */
@mixin media-query($type, $breakpoint: $lap-start) {
  @if $type == "min" {
    @media only screen and (min-width: $breakpoint) {
      @content;
    }
  } @else if $type == "max" {
    @media only screen and (max-width: $breakpoint - 1px) {
      @content;
    }
  } @else if $type == "palm" {
    @media only screen and (max-width: $lap-start - 1px) {
      @content;
    }
  } @else if $type == "lap" {
    @media only screen and (min-width: $lap-start) and (max-width: $desk-start - 1px) {
      @content;
    }
  } @else if $type == "desk" {
    @media only screen and (min-width: $desk-start) {
      @content;
    }
  } @else if $type == "wide" {
    @media only screen and (min-width: $wide-start) {
      @content;
    }
  } @else if $type == "retina" {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
      @content;
    }
  }
}

/**
 * Output media query block with min and max limits
 *
   `@include media-query-bracket(640px, 960px);`
 *
 */
@mixin media-query-bracket($from: 0, $to: 0) {
  @if $to > $from {
    @media only screen and (min-width: $from) and (max-width: $to - 1px) {
      @content;
    }
  }
}

/*========================================
Styling
========================================*/

/**
 * Output font size using rem and px fallback
 *
   `@include font-size(10px);`
 *
 */
@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size)*1rem;
  // line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
}

/**
 * Output unstyled list properties
 *
   `@include list-reset();`
 *
 */
@mixin list-reset() {
  margin-left: 0;
  list-style: none;
}

@mixin overlay-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  //background: transparent url(../img/spinner.gif) center no-repeat;
  background-size: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin button {
  display: inline-block;
  padding: ($half-spacing + 3) $base-and-half-spacing ($half-spacing + 3);
  width: auto;
  vertical-align: middle;
  background: $accent;
  color: $white;
  border: 1px solid $accent;
  line-height: $button-line-height;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: border 0.3s ease, background 0.3s ease, color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  border-radius: $half-radius;
  min-height: $button-height;


  &:hover,
  &:focus {
    color: $white;
    background-color: $accent-dk;
    text-decoration: none;
    outline: none;
  }

  &:active {
  }
}

@mixin container {
  width: 100%;
  max-width: $page-width;
  margin: 0 auto;
  padding: 0 $half-spacing;

  @include media-query (min, $lap-start) {
    padding: 0 $base-spacing;
  }
}
