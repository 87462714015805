// Google maps container
.map.shows-detail-map {
    height: 250px;
    @include media-query(min, $lap-start) {
        height: 400px;
    }
}

// Popup wrapper layout instructions
.gm-style-iw-d div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gm-style-iw-d {
    overflow: visible !important;

    // popup button colour
    .button-wrapper a {
        border: 1px solid transparent !important;
    }
}

// Popup container width
.gm-style-iw {
    max-width: 335px !important; //overriding google maps style injection
}

// Popout container
.gm-style .gm-style-iw-c {
    overflow: visible;
    padding-top: $base-spacing !important;
    padding-left: $base-spacing !important;
    padding-right: $base-spacing !important;
    padding-bottom: $base-spacing !important;
}

.gm-style-iw.gm-style-iw-c {
    max-height: 600px !important;
}


// close button
button.gm-ui-hover-effect {
    opacity: 1;

    &:after {
        position: absolute;
        top: -15px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        content: 'X';
        @include font-size(22px);
        color: white;
        background: $accent;
        border: 1px solid transparent;
        border-radius: 100%;
        height: 40px !important;
        width: 40px !important;

        &:hover {
            background: lighten($accent, 20%);
        }
    }

    // default image cross
    & img{
        display: none !important;
    }
}

.tool-exhibition__item-button.button {
    border: 1px solid transparent;
}

.shows-view-page__map {
    margin-bottom: $base-spacing;

    @include media-query(min, $lap-start) {
        margin-bottom: $double-spacing;
    }
}