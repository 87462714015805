/*========================================
PARAGRAPHS
========================================*/

.lede {
    @include font-size($lede-font-size);
    line-height: $lede-line-height;
}

small,
.small {
    @include font-size($small-font-size);
}

.small--bold {
    @include font-size($small-font-size);
    font-weight: bold;
}

.micro {
    @include font-size($micro-font-size);
}

caption,
.caption {
    padding-top: ($half-spacing / 2);
    margin-bottom: $half-spacing;
    @include font-size($small-font-size);
}

address {
    font-style: normal;
}
