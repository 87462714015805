.shows-index-index {
  .page-main {
    position: relative;
  }
}

.shows-page__mobile {
  @include media-query(min, $lap-start) {
    display: none;
  }
}

.shows-page__desktop {
  display: none;
  @include media-query(min, $lap-start) {
    display: block;
  }
}

.shows-page {
  @include media-query(min, $lap-start) {
    display: flex;
    margin-bottom: $double-spacing;
  }
}

.shows-page__title {
  @include font-size(33px);
  text-align: center;

  @include media-query(min, $lap-start) {
    text-align: left;
    @include font-size(45px);
    margin-bottom: 0;
    margin-right: $base-spacing;
  }
}

.shows-page__summary {
  p {
    @include font-size(13px);
    text-align: center;
    @include media-query(min, $lap-start) {
      text-align: left;
      @include font-size(15px);
    }
  }
}


.show-page__wrapper {

}

.shows-page__desktop {
  .show-page__wrapper {
    &:before {
      @include media-query(min, $lap-start) {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $silver-lt;
        top: 266px;
        left: 0;
      }
      @include media-query(min, $desk-start) {
        top: 218px;
      }
    }
  }
}

.show-page__tab {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-image: url("../img/expand_more_blk.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-query(min, $lap-start) {
      display: none;
    }
  }

  &.active {
    @include media-query(min, $lap-start) {
      background-color: $white;
      border-bottom: 1px solid $white;
    }
  }

  &.active:after {
    content: '';
    position: absolute;
    background-image: url("../img/expand_less_blk.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 24px;
    width: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include media-query(min, $lap-start) {
      display: none;
    }
  }

  @include media-query(min, $lap-start) {
    display: inline-block;
    @include font-size(18px);
    text-transform: uppercase;
    border: 1px solid $silver-lt;
    padding: $base-spacing;
    width: 49.6%;
    text-align: center;
    background-color: $silver-lt;
    margin-bottom: $double-spacing;
  }
}

.show-page__switch {
  text-decoration: none;
  display: block;
  position: relative;
  top: -4px;
}

.show-page__header {
  @include font-size(15px);
  @include media-query(min, $lap-start) {
    margin-bottom: 0;
  }
}

.tool-exhibition__content {

}

.tool-exhibition__list {
  @include media-query(min, $lap-start) {

  }

}

.tool-exhibition__list-header {
  @include font-size(15px);
  padding: $base-spacing;
  margin-bottom: $base-spacing;
  border: 1px solid $silver-lt;

  @include media-query(min, $lap-start) {
    display: block;
    width: 100%;
    border: none;
    @include font-size(18px);
    padding: 0;
  }
}

.tool-exhibition__item {
  margin-bottom: $base-spacing;
  border: 1px solid $silver-lt;
  cursor: pointer;
  @include media-query(min, $lap-start) {
    border: none;
    display: flex;
  }
}

.tool-exhibition__item-img {
  width: 100%;
  @include media-query(min, $lap-start) {
    margin-right: $double-spacing;
    width: auto;
  }
}

.tool-exhibition__item-content {
  padding: $base-spacing;

  @include media-query(min, $lap-start) {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }


  .content-wrapper {
    @include media-query(min, $lap-start) {
      width: calc(60% - #{$double-spacing});
    }

  }

  .button-wrapper {

  }


}

.tool-exhibition__item-title {
  @include font-size(15px);
  font-weight: bold;
  @include media-query(min, $lap-start) {
    @include font-size(22px);
  }
}

.tool-exhibition__item-desc {
  @include font-size(13px);
  @include media-query(min, $lap-start) {
    @include font-size(15px);
  }
}

.tool-exhibition__item-button {
  @include font-size(13px);
  font-style: italic;
  @include media-query(min, $lap-start) {
    @include font-size(15px);
  }
}

.tool-exhibition__item-date {
  @include font-size(15px);
  font-weight: bold;
  @include media-query(min, $lap-start) {
    @include font-size(18px);
  }
}