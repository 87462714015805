.social-buttons {

}

.social-message {
  display: inline-block;
  @include font-size(14px);
}

.social {
  display: inline-block;
  list-style-type: none;

  li {
    display: inline-block;
    margin-right: $half-spacing;
  }

  a {
    color: $silver-lt;;
  }
  .fa {
    font-size: 24px;
  }
}