.content-promo {
  text-align: center;
  margin-bottom: $double-spacing;

  @include media-query(min, $lap-start) {
    position: relative;
    display: flex;
    width: calc(100% - #{$half-spacing});
    margin-bottom: $double-spacing * 2;
  }
}

.content-promo__block {


}

.content-promo__block--two-up {
  @include media-query(min, $lap-start) {
    display: flex;
    justify-content: space-between;
    width: calc(66.666%);

    .content-promo__wrapper {
      width: calc(50% - 10px);
      transition: all .3s ease-in-out;
      cursor: pointer;

      @include hover {
        .content-promo__img-link  {
          transform: scaleY(1.05);
          transform-origin: bottom;
        }

        .content-promo__img {
          border: 2px solid $accent;

        }
      }
    }

    .content-promo__img-link {
      display: block;
      padding-top: 150%;
    }

    .content-promo__img-link {
      transition: all .3s ease-in-out;
      @include hover {
        transform: scaleY(1.05);
        transform-origin: bottom;
      }
    }
  }
}

.content-promo__block--three-up {
  @include media-query(min, $lap-start) {
    position: relative;
    right: -$base-spacing;
    width: calc(33.3333% - #{$half-spacing});

    .content-promo__wrapper {
      width: 100%;
      margin-bottom: $base-spacing;

      transition: all .3s ease-in-out;
      cursor: pointer;

      @include hover {

        .content-promo__img {
          border: 2px solid $accent;
        }

        .content-promo__img-link  {
          transform: scaleX(1.05);
          transform-origin: left;
        }

        .content-promo__link {
          transform: translateX(20px);
          transform-origin: left;
        }
      }



      &:last-child {
        margin-bottom: 0;
      }
    }

    .content-promo__img-link {
      display: block;
      padding-top: 50%;
      transition: all .3s ease-in-out;
    }

    .content-promo__sub-title {
      display: none;
    }

  }

}

.content-promo__wrapper {
  margin-bottom: $base-spacing;
  border: 1px solid $silver-lt;

  @include media-query(min, $lap-start) {
    position: relative;
    margin-bottom: 0;


  }


}

.content-promo__img-link {
  display: block;
  height: 100%;
  margin-bottom: $base-spacing;


  @include media-query(min, $lap-start) {
    margin-bottom: 0;

  }


}

.content-promo__img {
  width: 100%;


  @include media-query(min, $lap-start) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

}

.content-promo__content {
  @include media-query(min, $lap-start) {
    position: absolute;
    top: 0;
    left: $base-spacing;
    text-align: left;
  }
}

.content-promo__text-link {
  text-decoration: none;
  @include hover {
    color: $accent;
    text-decoration: none;
  }
}

.content-promo__title {

  @include font-size(15px);

  @include media-query(min, $lap-start) {
    @include font-size(42px);
    color: $white;
  }

}

.content-promo__sub-title {
  @include font-size(13px);

  @include media-query(min, $lap-start) {
    @include font-size(16px);
    color: $white;
  }


}

.content-promo__link {

  @include media-query(min, $lap-start) {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    z-index: 2;
    margin-bottom: 0;
    transition: all .3s ease-in-out;
  }

}